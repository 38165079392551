<template>
  <v-container fluid>
    <v-card v-if="error">

      <v-card-title>
        Signing Out
      </v-card-title>

      <v-card-text>
        {{ error }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
//import * as Msal from 'msal'

export default {
  props: ['error', 'requestedUrl'],

  methods: {
  },
}
</script>
